import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "2rem",
      }}
    >
      404, <pre style={{ fontSize: "1.5rem" }}> Page not found!</pre>
    </div>
  );
};

export default NotFound;
