import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Chat from "./pages/Chat";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Unauthorized from "./pages/Unauthorized";

import ProtectedRoute from "./routes/ProtectedRoute";
import AuthRoute from "./routes/AuthRoute";


const App = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={Chat} />
        <AuthRoute exact path="/login" component={Login} />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
