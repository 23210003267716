import { useState } from "react";

import { useHistory } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import Logo from "../assets/icons/google.svg";
import { signInWithGoogle } from "../utils/authSocial";
import { db } from "../utils/firebase";

const Login = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  const handleSignIn = () => {
    signInWithGoogle().then((res) => {
      if (res instanceof Error) {
        setError(res.message);
        return;
      }
      db.collection("users")
        .onSnapshot((querySnapshot) => {
          const users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            enabled: doc.data().enabled,
            uid: doc.data().uid,
          }));
          const userExists = (users, uid) => {
            return users.findIndex((user) => user.uid === uid) >= 0;
          };
          const getUser = (users, uid) => {
            return users.find((user) => user.uid === uid);
          };
          if (userExists(users, res.uid)) {
            const user = getUser(users, res.uid)
            if(user.enabled) {
              localStorage.setItem("uid", res.uid);
              history.replace("/");
            } else {
              history.replace("/unauthorized");
            }
          } else {
            db.collection("users").add({
              uid: res.uid,
              name: res.displayName,
              email: res.email,
              enabled: false,
            });
          }
        });
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100vh",
        fontSize: "2rem",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <h3
        style={{
          fontSize: "1.5rem",
          fontFamily: "san-serif",
          marginTop: "35vh",
          marginBottom: "10vh",
        }}
      >
        Welcome to the Azkar app 📋
      </h3>
      <Button onClick={handleSignIn} variant="outlined" color="primary">
        <img
          src={Logo}
          style={{ height: "1.5em", width: "1.5em", marginRight: "0.5em" }}
        />
        Sign In with google
      </Button>
    </div>
  );
};

export default Login;
