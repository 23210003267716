import { Link } from 'react-router-dom'

import Button from "@material-ui/core/Button";

import InputForm from "../components/InputForm";
import NoteList from "../components/NoteList";

const Chat = () => {
  return (
    <div
      style={{
        height: "96vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <NoteList />
      <InputForm />
    </div>
  );
};

export default Chat;
