import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
  const authenticated = !!localStorage.getItem("uid");
  return (
    <Route
      {...rest}
      render={() => {
        if (authenticated) return <Redirect to="/" />;
        return <Component />;
      }}
    />
  );
};

export default AuthRoute;
