import React from "react";

import { formatEpoch } from "../utils/datetime";

const Note = ({ note }) => {
  const time =
    note.timestamp === null
      ? (note.timestamp.seconds = new Date().getTime() / 1000)
      : formatEpoch(note.timestamp.seconds);

  return (
    <div
      style={{
        backgroundColor: note.backgroundColor || '#fee6ed',
        borderRadius: "10px",
        margin: "1em",
        paddingLeft: "1em",
        paddingRight: "1em",
      }}
    >
      <p style={{ fontSize: "1.3rem", overflowWrap: 'break-word' }}>{note.title}</p>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center'}}>
        <span style={{ fontSize: "1.2rem",  }}>{note.username}</span>
        <span style={{ fontSize: "0.8rem" }}>{time}</span>
      </div>
    </div>
  );
};

export default Note;
