import React from "react";

import { useHistory } from "react-router-dom";

const Unauthorized = () => {
  const history = useHistory()
  const relog = () => {
    history.replace("/login")
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "1.1rem",
      }}
    >
      <span>Please contact the admin</span>
      <button onClick={relog}>Retry</button>
    </div>
  );
};

export default Unauthorized;
