import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticated = !!localStorage.getItem("uid");
  return (
    <Route
      {...rest}
      render={() => {
        if (authenticated) return <Component />;
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
