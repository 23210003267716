import firebase from "./firebase";

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = () => {
  return firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then((res) => res.user)
    .catch((err) => err);
};

export { signInWithGoogle };
