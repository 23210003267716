import { useState, useEffect } from "react";

// import Alert from "@material-ui/lab/Alert";

import Note from "./Note";
import { db } from "../utils/firebase";

const NoteList = () => {
  const [notes, setNotes] = useState([]);
  const [modifiedNotes, setModifiedNotes] = useState([]);
  const [users, setUsers] = useState([]);
  // const [error, setError] = useState(null);
  const uid = localStorage.getItem("uid");

  const sortNotes = (notes) => {
    if (notes.length === 1)
      return [
        {
          ...notes[0],
          timestamp: { seconds: new Date().getTime() / 1000 },
        },
      ];
    return notes.sort((a, b) => {
      if (a.timestamp === null)
        a.timestamp = { seconds: new Date().getTime() / 1000 };
      if (b.timestamp === null)
        b.timestamp = { seconds: new Date().getTime() / 1000 };
      return a.timestamp.seconds - b.timestamp.seconds;
    });
  };

  useEffect(() => {
    db.collection("users").onSnapshot((querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        uid: doc.data().uid,
        backgroundColor: doc.data().backgroundColor
      }));
      setUsers(users);
    });
    db.collection("notes")
      .onSnapshot((querySnapshot) => {
        const notes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          timestamp: doc.data().timestamp,
          uid: doc.data().uid,
        }));
        const sortedNotes = sortNotes(notes);
        setNotes(sortedNotes);
      });
  }, []);

  useEffect(() => {
    if(notes.length > 0) {
      setModifiedNotes(notes.map( note => ({
        id: note.id,
        title: note.title,
        timestamp: note.timestamp,
        username: users.find(user => user.uid === note.uid).name,
        backgroundColor: users.find(user => user.uid === note.uid).backgroundColor,
      })))
    }
  }, [notes])

  return (
    <div style={{ overflowY: "scroll", height: "85vh" }}>
      {/* {error && <Alert severity="error">{error}</Alert>} */}
      {modifiedNotes.map((note) => (
        <Note key={note.id} note={note} />
      ))}
    </div>
  );
};

export default NoteList;
