import { useState } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";

import { db } from "../utils/firebase";
import firebase from "firebase";

const InputForm = () => {
  const [note, setNote] = useState("");

  const uid = localStorage.getItem("uid");

  const addNote = () => {
    if (note !== "") {
      db.collection("notes").add({
        uid,
        title: note,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setNote("");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        bottom: ".5em",
        left: 0,
        right: 0,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        style={{
          width: "20vw",
          height: "8vh",
          marginLeft: "1.5vw",
        }}
        onClick={addNote}
      >
        <SendIcon style={{ fontSize: 30, transform: "rotate(180deg)" }} />
      </Button>
      <TextField
        id="filled-basic"
        placeholder="ابدأ الكتابة..."
        variant="filled"
        style={{ width: "75vw", fontSize: "5rem", marginRight: "1vw" }}
        dir="rtl"
        inputProps={{
          style: { fontSize: 25 },
        }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </div>
  );
};

export default InputForm;
